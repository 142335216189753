<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="titleRules"
          label="Title"
          :helpText="'Provide provide a title or position of the customer.'"
          required
        ></HelpFormInputVue>

        <FormSection
          :label="'Company'"
          class="mt-0"
          :icon="'mdi-shield-key-outline'"
          :rules="companyRules"
          :actions="actions"
          @onCreate="() => $emit('onCompanyCreate')"
          @onList="() => $emit('onCompanyList')"
          underline
          right
        ></FormSection>

        <SelectorCustomerCompanyVue
          dark
          autoload
          :rules="companyRules"

          label="Customer Company"
          helpText="Pleas select a customer company. Please not that he will be able to edit this information."
          return-object
          v-model="form.company"
        ></SelectorCustomerCompanyVue>
  </v-form>
</template>
      
      
  <script>
import SelectorCustomerCompanyVue from "../../../atoms/common/autocompletes/SelectorCustomerCompany.vue";
import FormSection from "../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      titleRules: [(v) => !!v || "Title is required"],
      companyRules: [(v) => (!!v && !!v.id) || "Company is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
      actions: [
        {
          name: "Create",
          description: "Will create new Company",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
        {
          name: "List",
          description: "Will open Company List",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    FormSection,
    SelectorCustomerCompanyVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
      <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>