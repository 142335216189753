<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Name"
          :helpText="'Provide user name'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          v-model="form.email"
          :rules="emailRules"
          label="Email"
          :helpText="'Provide a user email. This email will be used for login and invitation'"
          required
        ></HelpFormInputVue>

        <FormSection
          :label="'Roles'"
          class="mt-0"
          :icon="'mdi-shield-key-outline'"
          :actions="actions"
          @onCreate="() => $emit('onRoleCreate')"
          underline
          right
        ></FormSection>

        <SelectorRolesVue
          dark
          autoload
          label="Roles"
          helpText="You can specify multiple roles to manage user access to the system features"
          return-object
          :rules="rolesRules"
          v-model="form.roles"
          multiple
        >
        </SelectorRolesVue>

        <FormSection
          :label="'User Type'"
          class="mt-0 mb-6"
          :icon="'mdi-account'"
          :editable="false"
          underline
          right
        ></FormSection>

        <SelectorDefault
          dark
          v-model="userType"
          :label="'User Type'"
          :items="userTypes"
          :helpText="'Please select a user type. Each type has a unique sign up process and access.'"
        ></SelectorDefault>

        <NewEmployeeForm
          ref="employeeForm"
          v-if="form.employee"
          v-model="form.employee"
        ></NewEmployeeForm>
        <NewCustomerForm
          ref="customerForm"
          v-if="form.customer"
          v-model="form.customer"
        ></NewCustomerForm>

        <v-alert class="my-2" dense outlined type="success">
          After creation the system will send an invitation to the user. Please
          make sure that email is correct.
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
<script>
import SelectorDefault from "../../../atoms/common/autocompletes/SelectorDefault.vue";
import SelectorRolesVue from "../../../atoms/common/autocompletes/SelectorRoles.vue";
// import Selector from "../../../atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import FormSection from "../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";
import NewCustomerForm from "./NewCustomerForm.vue";
import NewEmployeeForm from "./NewEmployeeForm.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: {
        ...(this.value ? this.value : {}),
        employee: {},
      },

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rolesRules: [
        (v) =>
          (!!v && !!v.length) || "Roles are required. Please specify at least one",
      ],

      descriptionRules: [(v) => !!v || "Description is required"],
      actions: [
        {
          name: "Create",
          description: "Will create new Tag",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
      userType: "employee",
      userTypes: [
        {
          name: "Customer",
          value: "customer",
          description:
            'Customer will have associated company and other information that <br> allows to order services in ADAAS System. <br><br> <span style="color:red">Please make sure that a  <strong>VALID </strong> Customer role is assigned </span>',
        },
        {
          name: "Employee",
          value: "employee",
          description:
            'Employee is an actual ADAAS Employee. <br><br> <span style="color:red">Please make sure that a  <strong>VALID </strong> Employee role is assigned </span>',
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorRolesVue,
    FormSection,
    SelectorDefault,
    NewEmployeeForm,
    NewCustomerForm,
  },
  methods: {
    validate() {
      return (
        this.$refs.form.validate() &&
        (this.form.customer
          ? this.$refs.customerForm.validate()
          : this.$refs.employeeForm.validate())
      );
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
    userType(newVal) {
      if (newVal === "employee") {
        this.form.customer = undefined;
        this.form.employee = {};
      } else {
        this.form.customer = {};
        this.form.employee = undefined;
      }
    },
  },
};
</script>
    
    
    <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>