<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <NewUserFormVue
        ref="form"
        class="pa-4"
        v-model="form"
        @onRoleCreate="onRoleCreate"
      ></NewUserFormVue>
    </template>
  </AView>
</template>
  
  <script>
import NewUserFormVue from '../../../../../../components/wad/organisms/users/forms/NewUserForm.vue';
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";


export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    NewUserFormVue,
    AView,
  },
  data: () => ({
    form: {},
    actions: [
      {
        name: "Create",
        description: "Will create new Customer",
        icon: "mdi-check",
        color: "#23842a",
        on: "onCreate",
      },
      {
        name: "Close",
        description: "Will close this window",
        icon: "mdi-trash-can-outline",
        color: "#0e1721",
        on: "onClose",
      },
    ],
  }),
  created() {
    console.log("CUSTOMER CREATED==========", this.value.isFocused);
  },

  computed: {},

  methods: {
    onFocus(data) {
      console.log("=============Data: ", data);
    },
    onRoleCreate() {
      this.$store.dispatch("RoleStore/openRoleCreation", {
        from: this.value,
      });
    },
    onCreate() {
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("UserStore/CreateNewUser", {
          from: this.value,
          newUser: this.form,
        });
    },
  },
};
</script>