<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.title"
      :rules="titleRules"
      label="Title"
      :helpText="'Please provide employees title. It should describe what responsibilities employee has.'"
      required
    ></HelpFormInputVue>

    <SelectorDefault
      dark
      v-model="form.allocation"
      :label="'Allocation Type'"
      :items="allocations"
      :helpText="'Please select employee\'s allocation type. This information will help to adjust a work in the right way'"
    ></SelectorDefault>

    <!-- <v-alert class="my-2" dense outlined type="warning">
          This information can be changed by employee himself during sign-up or
          later
        </v-alert> -->
  </v-form>
</template>
      
      
  <script>
import SelectorDefault from "../../../atoms/common/autocompletes/SelectorDefault.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";


export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value ? this.value : {},

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      titleRules: [(v) => !!v || "Title is required"],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      descriptionRules: [(v) => !!v || "Description is required"],
      allocations: [
        {
          name: "Full Time",
          value: "FULL_TIME",
          description:
            "In this case employee should be able to perform all import work that comes from customers. <br> The employee should be available at least  <strong>160h per month</strong>",
        },
        {
          name: "Part Time",
          value: "PART_TIME",
          description:
            "In this case employee allocated only partially and may be unavailable for a specific work. <br> The employee should be available at least  <strong>40h per month</strong>",
        },
        {
          name: "Only Consulting",
          value: "ONLY_CONSULTING",
          description:
            "In this case employee allocated <strong> CAN NOT </strong> perform any urgent work but can help on any consulting-related issues. <br> The employee may be available less than  <strong>40h per month</strong>",
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorDefault
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
<style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>